import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["alertPanel", "deliveryCount"];

  static values = {
    deliveryDate: String
  };

  connect() {
    this.toggleAlertPanel();
  }

  toggleAlertPanel() {
    const deliveryCount = this.deliveryCountTarget.textContent;
    this.alertPanelTarget.style.display = deliveryCount >= 1 ? "block" : "none";
  }

  updateDeliveryDate(event) {
    this.deliveryDateValue = event.currentTarget.value;
    this.getAffectedDeliveries();
  }

  async getAffectedDeliveries() {
    const res = await fetch(`date_effects/${this.deliveryDateValue}`);
    const jres = await res.json();

    this.deliveryCountTarget.textContent = jres.delivery_count;
    this.toggleAlertPanel();
  }
}
