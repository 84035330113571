import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

// Connects to data-controller="slim-select2"
export default class extends Controller {
  static values = {
    selectUrl: String,
  };

  static outlets = ["slim-select2"];

  timeout = undefined;

  connect() {
    this.previousValue = this.element.value;

    this.select = new SlimSelect({
      select: this.element,
      events: {
        ...(this.hasSelectUrlValue && {
          search: (search, currentData) => {
            return new Promise((resolve, reject) => {
              clearTimeout(this.timeout);

              if (search.length < 3) {
                return reject("Search must be at least 3 characters");
              }

              this.timeout = setTimeout(() => {
                this.fetchOptions(search, resolve, reject);
              }, 300);
            });
          },
        }),
      },
    });

    this.dispatch("controllerConnected", {
      detail: { controller: this },
    });
  }

  setOptions(options) {
    this.select.setData(options);
  }

  getSelected() {
    return this.select.getSelected();
  }

  setSelected(value) {
    this.select.setSelected(value);
  }

  fetchOptions(search, resolve, reject) {
    let url = `${this.selectUrlValue}?search=${encodeURIComponent(search)}`;

    if (this.hasSlimSelect2Outlet) {
      url = `${this.selectUrlValue}/${this.slimSelect2Outlet.element.value}?search=${search}`;
    }

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((item) => {
          return {
            text: `${item.text}`,
            value: `${item.id}`,
            html: `${item.html}`,
          };
        });

        resolve(options);
      });
  }

  onValueChanged() {
    if (this.previousValue == this.element.value) {
      return;
    }

    this.previousValue = this.element.value;

    this.dispatch("valueChange", {
      detail: { value: this.previousValue, select: this.select },
    });
  }
}
