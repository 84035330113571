import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["visible"];

  connect() {
    document.addEventListener("click", this.hideOnClickOutside.bind(this));
  }

  toggle() {
    if (this.visibleTarget) {
      if (this.visibleTarget.classList.contains("hidden")) {
        this.visibleTarget.classList.remove("hidden");
      } else {
        this.visibleTarget.classList.add("hidden");
      }
    }
    event.stopPropagation();
  }

  hideOnClickOutside() {
    if (this.visibleTarget && document.activeElement !== this.visibleTarget) {
      this.visibleTarget.classList.add("hidden");
    }
  }
}