import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="smart-form"
export default class extends Controller {
  static targets = [
    "validate"
  ]

  connect() {
    if (this.element.action) {
      this.listenToForm(this.element);
    } else {
      var elements = this.element.querySelectorAll('form')

      if (elements.length > 0) {
        this.listenToForm(elements[0]);
      }  
    }
  }

  listenToForm(form) {
    form.addEventListener('submit', (event) => {
      let formIsValid = form.checkValidity();

      if (!formIsValid) {
        event.preventDefault();
        event.stopPropagation();

        this.validateEachTarget();
      }

      // disable inputs
      var elements = this.element.querySelectorAll('input[type="submit"]')

      if (elements.length > 0) {
        elements[0].disable = true;
      }

      // disable buttons
      var elements = this.element.querySelectorAll('button[type="submit"]')

      if (elements.length > 0) {
        elements[0].disabled = true;
      }
    });
  }

  validateEachTarget() {
    this.validateTargets.forEach((target) => {
      var element= target.querySelector('select, input');
      var isValid = element.checkValidity();
      var feedbackElement = target.querySelector('.invalid-feedback');

      if (!feedbackElement) {
        return;
      }

      var displayValue = 'none';

      if (!isValid) {
        displayValue = 'block';
      }

      feedbackElement.style.display = displayValue;
    });
  }
}