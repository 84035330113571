import DataTable from "datatables.net";
import "datatables.net-select";

window.DataTable = DataTable();

["turbo:frame-load", "turbo:load", "turbo:render"].forEach(function (e) {
  window.addEventListener(e, () => {
    const tables = $(".datatable")
      .filter(":not(.dataTable)")
      .DataTable({
        responsive: true,
        language: {
          paginate: {
            previous: "‹ Prev",
            next: "Next ›",
          },
        },
        initComplete: function () {
          if (this.api().page.info().pages <= 1) {
            id = "#" + this.attr("id");
            $(id + "_paginate").hide();
            $(id + "_length").hide();
            $(id + "_filter").hide();
          }
        },
      });

    $(document).on("turbo:before-cache", () => {
      tables.destroy();
    });
  });
});
