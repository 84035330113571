import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-line-items"
export default class extends Controller {
  static targets = ["price", "quantity", "total"];

  connect() {
    this.debouncedOnQuantityChange = this.debounce(this.onPriceChange.bind(this), 300);
    this.debouncedOnPriceChange = this.debounce(this.onPriceChange.bind(this), 300);
  }

  onQuantityChange(event) {
    this.refreshTotal();
  }

  onPriceChange(event) {
    this.refreshTotal();
  }

  refreshTotal() {
    let quantity = Number(this.quantityTarget.value);
    let price = Number(this.priceTarget.value).toFixed(2);
    let total = (price * quantity).toFixed(2);

    // update total
    this.totalTarget.innerHTML = `£${total}`;

    this.dispatch("lineItemTotalChange", { detail: { value: total } })
  }

  debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
}