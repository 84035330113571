import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "availableVariantsTable",
    "basketTypes",
    "discountFieldGroup",
    "discountsOrders",
    "discountsOrdersLabel",
    "firstOrder",
    "maxItemsField",
    "maxItemsRequired",
    "maximumDiscount",
    "maximumDiscountField",
    "minimumSpend",
    "minimumSpendField",
    "minimumSpendRequired",
    "selectedSvgTemplate",
    "selectedVariantTemplate",
    "selectedVariantsTable",
    "value",
    "valueGbp",
    "valuePercentage",
    "valuePound",
    "valueType",
    "valueTypeField",
    "variantTablesSpan",
    "variantsSubform",
    "voucherType"
  ];

  static values = {
    defaultMinimumSpend: String,
    voucherType: String
  };

  connect() {
    this.voucherTypeTarget.value = this.voucherTypeValue;
    this.refreshFields();
    this.addEventListeners();
    this.setDiscountsOrdersLabel();
  }

  addEventListeners() {
    const formFields = this.element.querySelectorAll("input, select");
    formFields.forEach((field) => {
      field.addEventListener("change", this.refreshFields.bind(this));
    });

    this.element.addEventListener("submit", this.handleSubmit.bind(this));
  }

  refreshFields() {
    this.refreshDiscountsFieldsGroup();
    this.refreshMaxItems();
    this.refreshVariantsSubform();
    this.refreshMinimumSpend();
  }

  refreshDiscountsFieldsGroup() {
    this.discountFieldGroupTarget.style.display = this.evaluateDiscountFields() ? "block" : "none";
    this.refreshValueTypeField();
    this.refreshValueField();
    this.refreshMaximumDiscountField();
  }

  refreshValueTypeField() {
    this.valueTypeFieldTarget.style.display = this.voucherTypeValue === "CreditVoucher" ? "none" : "block";
  }

  refreshValueField() {
    if (this.voucherTypeValue !== "CreditVoucher" && this.valueTypeTarget.value === "percentage") {
      this.valuePoundTarget.style.display = "none";
      this.valuePercentageTarget.style.display = "block";
      this.valueGbpTarget.style.display = "none";
    } else {
      this.valuePoundTarget.style.display = "block";
      this.valuePercentageTarget.style.display = "none";
      this.valueGbpTarget.style.display = "block";
    }
  }

  refreshMaximumDiscountField() {
    this.maximumDiscountFieldTarget.style.display = this.valueTypeTarget.value === "percentage" ? "block" : "none";
  }

  refreshMaxItems() {
    this.maxItemsFieldTarget.style.display = this.voucherTypeValue === "DiscountVoucher" && !this.discountsOrdersTarget.checked ? "block" : "none";
    this.maxItemsRequiredTarget.textContent = this.voucherTypeValue === "DiscountVoucher" && !this.discountsOrdersTarget.checked ? "*" : "";
  }

  refreshVariantsSubform() {
    if (
      (this.voucherTypeValue === "DiscountVoucher" && !this.discountsOrdersTarget.checked) ||
      (this.voucherTypeValue === "LoyaltyVoucher" && this.firstOrderTarget.checked) ||
      this.voucherTypeValue === "AddonVoucher"
    ) {
      this.variantsSubformTarget.style.display = "block";
      this.variantTablesSpanTarget.textContent =
        this.voucherTypeValue === "AddonVoucher" || (this.voucherTypeValue === "DiscountVoucher" && !this.discountsOrdersTarget.checked)
          ? "*"
          : "(Optional)";
      $(this.availableVariantsTableTarget).DataTable({ order: [[1, "asc"]], bDestroy: true });
    } else {
      this.variantsSubformTarget.style.display = "none";

      // Destroy selected variant rows
      const rows = this.selectedVariantsTableTarget.querySelector('tbody').querySelectorAll('tr');
      rows.forEach(row => {
        row.remove();
      });
    }
  }

  refreshMinimumSpend() {
    if (["AddonVoucher", "DiscountVoucher", "LoyaltyVoucher"].includes(this.voucherTypeValue)) {
      this.minimumSpendFieldTarget.style.display = "block";
      this.minimumSpendRequiredTarget.textContent =
        (["DiscountVoucher", "LoyaltyVoucher"].includes(this.voucherTypeValue) ||
          (this.voucherTypeValue === "AddonVoucher" && this.discountsOrdersTarget.checked))
          ? "*"
          : "";
    } else {
      this.minimumSpendFieldTarget.style.display = "none";
    }
  }

  evaluateDiscountFields() {
    if (["CreditVoucher", "DiscountVoucher", "LoyaltyVoucher"].includes(this.voucherTypeValue)) {
      return true;
    }

    if (this.voucherTypeValue === "AddonVoucher" && this.discountsOrdersTarget.checked) {
      return true;
    }

    return false;
  }

  setDiscountsOrdersLabel() {
    if (this.voucherTypeValue === "LoyaltyVoucher") {
      this.discountsOrdersLabelTarget.textContent = "Discount applies to the whole order";
    } else if (this.voucherTypeValue === "AddonVoucher") {
      this.discountsOrdersLabelTarget.textContent =
        "Apply discount to the order (in addition to free addon products)";
    } else if (this.voucherTypeValue === "DiscountVoucher") {
      this.discountsOrdersLabelTarget.textContent =
        "Apply discount to the whole order (untick if you want to discount specific products)";
    }
  }

  selectVariant(event) {
    event.preventDefault();
    const variantId = event.currentTarget.dataset.variantId;
    const variantType = event.currentTarget.dataset.variantType;
    const variantTitle = event.currentTarget.dataset.variantTitle;

    // Check if variant already exists in selected table
    const existingRow = this.selectedVariantsTableTarget.querySelector(`[data-variant-id="${variantId}"]`);
    if (existingRow) {
      // Variant already exists in selected table, do not add again
      return;
    }

    // Set content for new row in selected variants table
    const row = this.selectedVariantTemplateTarget.content.cloneNode(true);
    row.querySelector(".idInput").value = variantId;
    const quantityInput = row.querySelector(".quantityInput");
    if (quantityInput) { quantityInput.value = 1; }
    row.querySelector(".type").textContent = variantType;
    row.querySelector(".id").textContent = variantId;
    row.querySelector(".title").textContent = variantTitle;
    row.querySelector(".removeButton").dataset.variantId = variantId;
    row.querySelector("tr").dataset.variantId = variantId;

    // Add new row to selected variants table
    this.selectedVariantsTableTarget.querySelector("tbody").appendChild(row);

    // Find matching row in available variants table and add selected svg icon
    const availableRow = this.availableVariantsTableTarget.querySelector(`[data-variant-id="${variantId}"]`);

    if (availableRow) {
      const selectedSvg = this.selectedSvgTemplateTarget.innerHTML;
      availableRow.querySelector(".selectedIcon").innerHTML = selectedSvg;
    }
  }

  removeVariant(event) {
    event.preventDefault();

    // Find row & remove
    event.currentTarget.closest("tr").remove();

    // Find matching row in available variants table and remove selected svg icon
    const variantId = event.currentTarget.dataset.variantId;
    const availableRow = this.availableVariantsTableTarget.querySelector(`[data-variant-id="${variantId}"]`);

    if (availableRow) {
      availableRow.querySelector(".selectedIcon").innerHTML = '';
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const type = this.voucherTypeValue;
    const discountsOrders = this.hasDiscountsOrdersTarget && this.discountsOrdersTarget.checked;
    const value = Number(this.valueTarget.value);
    const valueType = this.valueTypeTarget.value;

    if (type !== 'CreditVoucher' && discountsOrders && value && valueType) {
      const maximumDiscount = Number(this.maximumDiscountTarget.value);
      let minimumSpend = Number(this.minimumSpendTarget.value || this.defaultMinimumSpendValue);
      let minimumSpendPerOrder = minimumSpend;
      let minimumTotalAfterDiscount = 0;
      let minimumPerOrderAfterDiscount = 0;
      let discount = 0;

      if (valueType === 'fixed_amount') {
        minimumTotalAfterDiscount = minimumSpend - value;
        minimumPerOrderAfterDiscount = minimumSpendPerOrder - value;
      } else {
        discount = minimumSpend * value / 100;
        if (maximumDiscount && discount > maximumDiscount) discount = maximumDiscount;
        minimumTotalAfterDiscount = minimumSpend - discount;
        discount = minimumSpendPerOrder * value / 100;
        if (maximumDiscount && discount > maximumDiscount) discount = maximumDiscount;
        minimumPerOrderAfterDiscount = minimumSpendPerOrder - discount;
      }

      let display = false;
      let displayValue = 0;

      if (minimumTotalAfterDiscount < 10 || minimumPerOrderAfterDiscount < 10) {
        displayValue = Math.min(minimumTotalAfterDiscount, minimumPerOrderAfterDiscount);
        display = true;
      }

      if (display) {
        if (displayValue < 0) displayValue = 0;
        if (!confirm(`ATTENTION! This voucher will enable customers to place orders with a final value of £${displayValue}. You should not create this voucher unless you are absolutely sure this is intentional!`)) {
          return;
        }
      }
    }

    this.element.submit();
  }
}
