import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      draggable: "tr",
      handle: ".drag-handle",
      dragClass: "drag-item",
      chosenClass: "chosen-item",
      animation: 150,
      ghostClass: 'bg-blue-200',
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    const id = event.item.dataset.id;
    const dispatch_rule_id = event.item.dataset.ruleId;
    const newIndex = event.newIndex + 1;

    fetch(`/dispatch_rules/${dispatch_rule_id}/fleets/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ dispatch_rule_fleet: { position: newIndex } }),
      credentials: 'same-origin'
    }).then(response => {
      if (response.ok) {
        Turbo.visit(window.location.href, { action: 'replace' });
      }
    });
  }
}