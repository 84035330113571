window.addEventListener('turbo:load', () => {
  $('#mbl-sidebar-open').click(function () {
    $('#mbl-sidebar').removeClass('hidden');
  });

  $('#mbl-sidebar-close').click(function () {
    $('#mbl-sidebar').addClass('hidden');
  });

  $(".sidebar-group-button").click(function () {
    elem = $(this)

    let arrow = elem.children(".sidebar-arrow")
    let subMenu = elem.siblings(".sidebar-group-sub-menu")

    if (subMenu.hasClass('hidden')) {
      subMenu.removeClass('hidden')
      arrow.addClass('rotate-90')
    } else {
      subMenu.addClass('hidden')
      arrow.removeClass('rotate-90')
    }
  });
})