import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('ChangeSkus controller connected to ' + this.element.id);
  }

  static targets = ["oldSku", "newSku", "dryRunToken", "messageBox", "message"]

  async getAffectedDeliveries() {
    const token = document.querySelector("meta[name=csrf-token]").content;

    const res = await fetch('dry_run', {
      method: 'POST',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        old_sku: this.oldSkuTarget.value,
        new_sku: this.newSkuTarget.value
      })
    });
    const jres = await res.json();

    this.messageTarget.textContent = jres.message;
    this.dryRunTokenTarget.value = jres.dry_run_token;

    this.messageBoxTarget.hidden = false;
  }

  dryRun() {
    this.getAffectedDeliveries();
  }
}
