import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["eventLogs",
                    "eventData",
                    "eventName",
                    "eventReportedAt",
                    "eventAuthor"];

  static values = {
    eventLogs: String
  };

  connect() {
    this.event_logs = JSON.parse(this.eventLogsValue);
    this.selected_item = 0;
    this.selected_object = {};

    if (this.event_logs.length > 0) {
      this.selectItemById(this.event_logs[0].id);
    }
  }

  eventLogById(id) {
    return this.event_logs.find((el) => el.id === id);
  }

  selectItem(event) {
    const trElement = event.target.closest("tr");
    const eventId = parseInt(trElement.dataset.eventLogId);
    this.selectItemById(eventId);
  }

  selectItemById(id) {
    this.selected_item = id;
    this.selected_object = this.eventLogById(this.selected_item);
    this.updateSelectedObjectData();

    // Remove 'bg-yellow-100' class from previously selected tr element
    const prevSelectedTr = this.eventLogsTarget.querySelector(".bg-yellow-100");
    if (prevSelectedTr) {
      prevSelectedTr.classList.remove("bg-yellow-100");
    }

    // Add 'bg-yellow-100' class to the selected tr element
    const trElement = this.eventLogsTarget.querySelector(`[data-event-log-id="${id}"]`);
    trElement.classList.add("bg-yellow-100");
  }

  updateSelectedObjectData() {
    this.eventNameTarget.textContent = this.selected_object.event_name;
    this.eventReportedAtTarget.textContent = this.selected_object.reported_at;
    this.eventAuthorTarget.textContent = this.selected_object.author;

    const eventDataContainer = this.eventDataTarget;
    eventDataContainer.innerHTML = "";

    Object.entries(this.selected_object.event_data).forEach(([key, value]) => {
      const dtElement = document.createElement("dt");
      dtElement.className = "text-sm font-medium text-gray-500";
      dtElement.textContent = key;

      const ddElement = document.createElement("dd");
      ddElement.className = "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2";
      if (typeof value === 'string' && value.startsWith('/')) {
        ddElement.innerHTML = "<a href=" + value + ">See details</a>";
      } else {
        ddElement.textContent = value;
      }

      const eventItemElement = document.createElement("div");
      eventItemElement.className = "py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6";
      eventItemElement.appendChild(dtElement);
      eventItemElement.appendChild(ddElement);

      eventDataContainer.appendChild(eventItemElement);
    });
  }
}