import { Controller } from "@hotwired/stimulus";
import Mustache from "mustache";

// Connects to data-controller="customer-address"
export default class extends Controller {
  static targets = [
    "customer",
    "product",
    "orderForm",
    "orderTotal",
    "pendingValue",
    "payment",
    "tableSection",
    "voucherCode",
    "newVoucherCode",
    "voucherPopover",
    "shippingFee",
    "submitButton",
    "newShippingFee",
    "shippingFeePopover",
    "shippingAddressPopover",
    "billingAddressPopover",
    "billingFirstName",
    "billingLastName",
    "billingCompany",
    "billingPhone",
    "billingAddress1",
    "billingAddress2",
    "billingCity",
    "billingCountry",
    "billingRegion",
    "billingPostcode",
    "shippingFirstName",
    "shippingLastName",
    "shippingCompany",
    "shippingPhone",
    "shippingAddress1",
    "shippingAddress2",
    "shippingCity",
    "shippingCountry",
    "shippingRegion",
    "shippingPostcode",
    "addressHtmlTemplate",
    "addressTextTemplate",
    "shippingAddress",
    "billingAddress",
  ];

  static outlets = ["slim-select2"];

  static values = {
    addresses: Array,
    addressList: Array,
    sourceOrderTotal: Number,
    shippingAddressId: String,
    billingAddressId: String
  };

  connect() {
    this.addressOptions = this.buildAddressOptions();
    this.orderTotal = parseFloat(this.orderTotalTarget.innerHTML.trim().substring(1));
    this.pendingValue = 0;
  }

  async onCustomerChanged() {
    // fetch customer addresses
    const customerId = this.customerTarget.value;

    let options = await this.fetchCustomerAddresses(customerId);

    this.addressesValue = options;
    this.addressOptions = this.buildAddressOptions();

    this.updateAddressOptions();
  }

  async fetchCustomerAddresses(customerId) {
    const url = `/customer_address_options?customer=${customerId}`;

    let response = await fetch(url, {
      method: "GET",
    });

    let data = await response.json();

    return data;
  }

  async validatePhone(phone) {
    const url = `/validate_phone?phone=${encodeURIComponent(phone)}`;

    let response = await fetch(url, {
      method: "GET",
    });

    let data = await response.json();

    return data;
  }

  async validateOrder(variantToRemove = null) {
    const token = document.querySelector("meta[name=csrf-token]").content;
    const response = await fetch("/shopify_orders/validate", {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.extractFormData(variantToRemove)),
    });

    const htmlContent = await response.text();

    this.tableSectionTarget.outerHTML = htmlContent;

    this.orderTotal = parseFloat(this.orderTotalTarget.innerHTML.trim().substring(1));

    this.updatePendingValue();
  }

  updatePendingValue() {
    var newValue = Math.round((this.orderTotal - this.sourceOrderTotalValue) * 100) / 100;

    if (newValue < 0) {
      this.paymentTarget.value = 'refund';
    }

    if (newValue == 0) {
      this.paymentTarget.value = 'paid';
    }

    if (newValue > 0) {
      this.paymentTarget.value = 'pending';
    }

    this.pendingValue = Math.abs(newValue);
    this.pendingValueTarget.value = this.pendingValue;
  }

  removeDiscount() {
    this.voucherCodeTarget.value = "";

    this.validateOrder();
  }

  extractFormData(variantToRemove = null) {
    const form = new FormData(this.orderFormTarget);
    const formKeys = new Set(form.keys());
    const result = [];

    formKeys.forEach((key) => {
      if (key.endsWith("[]")) {
        result.push([key.replace("[]", ""), form.getAll(key)]);
        return;
      }

      result.push([key, form.get(key)]);
    });

    if (variantToRemove != null) {
      result.push(["shopify_order[remove_variant]", variantToRemove]);
    }

    return Object.fromEntries(result);
  }

  async removeVariant(event) {
    const variantId = event.currentTarget.dataset.variantId;

    this.validateOrder(variantId);
  }

  outletConnected(event) {
    const element = event.srcElement;

    if (element == this.customerTarget || element == this.productTarget) {
      return;
    }

    const outlet = event.detail.controller;

    if (element == this.shippingAddressTarget) {
      this.shippingAddressOutlet = outlet;

      this.updateAddressOptionsToOutlet(outlet);

      this.shippingAddressOutlet.setSelected(this.shippingAddressIdValue);
    }

    if (element == this.billingAddressTarget) {
      this.billingAddressOutlet = outlet;

      this.updateAddressOptionsToOutlet(outlet);

      this.billingAddressOutlet.setSelected(this.billingAddressIdValue);
    }
  }

  onSelectValueChanged(data) {
    if (data.srcElement == this.customerTarget) {
      this.onCustomerChanged();
    }
    if (data.srcElement == this.productTarget) {
      this.onProductChanged();
    }
  }

  async onProductChanged() {
    this.validateOrder();
  }

  paymentChanged(event) {
    if (this.paymentTarget.value == "paid") {
      this.pendingValueTarget.value = 0;
      this.pendingValueTarget.disabled = true;
      return;
    }

    this.pendingValueTarget.value = this.pendingValue;
    this.pendingValueTarget.disabled = false;
  }

  lineItemTotalChange(event) {
    this.validateOrder();
  }

  // customer addresses

  findOutletForElement(element) {
    this.slimSelect2Outlets.find((item) => {
      return item.element == element;
    });
  }

  updateAddressOptionsToOutlet(item) {
    item.setOptions(this.addressOptions);
  }

  updateAddressOptions() {
    this.slimSelect2Outlets.forEach((item) => {
      if (
        item.element != this.customerTarget &&
        item.element != this.productTarget
      ) {
        this.updateAddressOptionsToOutlet(item);
      }
    });
  }

  buildAddressHtml(address) {
    const template = this.addressHtmlTemplateTarget;

    return Mustache.render(template.innerHTML, address);
  }

  buildAddressText(address) {
    const template = this.addressTextTemplateTarget;

    return Mustache.render(template.innerHTML, address);
  }

  buildAddressOption(address) {
    return {
      text: this.buildAddressText(address).trim(),
      value: `${address.id}`,
      html: this.buildAddressHtml(address).trim(),
    };
  }

  buildAddressOptions() {
    return this.addressesValue.map((address) => {
      return this.buildAddressOption(address);
    });
  }

  // address popover

  copyShippingAddressToPopover(address) {
    this.shippingFirstNameTarget.value = address.first_name;
    this.shippingLastNameTarget.value = address.last_name;
    this.shippingCompanyTarget.value = address.company;
    this.shippingPhoneTarget.value = address.phone;
    this.shippingAddress1Target.value = address.address1;
    this.shippingAddress2Target.value = address.address2;
    this.shippingCityTarget.value = address.city;
    this.shippingCountryTarget.value = address.country;
    this.shippingRegionTarget.value = address.region;
    this.shippingPostcodeTarget.value = address.zip;
  }

  copyBillingAddressToPopover(address) {
    this.billingFirstNameTarget.value = address.first_name;
    this.billingLastNameTarget.value = address.last_name;
    this.billingCompanyTarget.value = address.company;
    this.billingPhoneTarget.value = address.phone;
    this.billingAddress1Target.value = address.address1;
    this.billingAddress2Target.value = address.address2;
    this.billingCityTarget.value = address.city;
    this.billingCountryTarget.value = address.country;
    this.billingRegionTarget.value = address.region;
    this.billingPostcodeTarget.value = address.zip;
  }

  buildShippingAddressFromPopover(phoneValidation) {
    return {
      id: "editShipping",
      first_name: this.shippingFirstNameTarget.value,
      last_name: this.shippingLastNameTarget.value,
      company: this.shippingCompanyTarget.value,
      phone: phoneValidation.phone,
      phone_valid: phoneValidation.valid,
      address1: this.shippingAddress1Target.value,
      address2: this.shippingAddress2Target.value,
      city: this.shippingCityTarget.value,
      country: this.shippingCountryTarget.value,
      region: this.shippingRegionTarget.value,
      zip: this.shippingPostcodeTarget.value,
    };
  }

  buildBillingAddressFromPopover(phoneValidation) {
    return {
      id: "editBilling",
      first_name: this.billingFirstNameTarget.value,
      last_name: this.billingLastNameTarget.value,
      company: this.billingCompanyTarget.value,
      phone: this.billingPhoneTarget.value,
      phone_valid: phoneValidation.valid,
      address1: this.billingAddress1Target.value,
      address2: this.billingAddress2Target.value,
      city: this.billingCityTarget.value,
      country: this.billingCountryTarget.value,
      region: this.billingRegionTarget.value,
      zip: this.billingPostcodeTarget.value,
    };
  }

  openShippingAddressPopover() {
    this.closeBillingAddressPopover();

    const currentAddressValue = this.shippingAddressTarget.value;

    const addressItem = this.addressesValue.find((item) => {
      return item.id == currentAddressValue;
    });

    // copy address to popover
    if (addressItem) {
      this.copyShippingAddressToPopover(addressItem);
    }

    this.shippingAddressPopoverTarget.classList.remove("invisible");
  }

  openBillingAddressPopover() {
    this.closeShippingAddressPopover();

    const currentAddressValue = this.billingAddressTarget.value;

    const addressItem = this.addressesValue.find((item) => {
      return item.id == currentAddressValue;
    });

    // copy address to popover
    if (addressItem) {
      this.copyBillingAddressToPopover(addressItem);
    }

    this.billingAddressPopoverTarget.classList.remove("invisible");
  }

  closeShippingAddressPopover() {
    this.shippingAddressPopoverTarget.classList.add("invisible");
  }

  closeBillingAddressPopover() {
    this.billingAddressPopoverTarget.classList.add("invisible");
  }

  async shippingAddressApply() {
    this.closeShippingAddressPopover();

    const phoneValidation = await this.validatePhone(this.shippingPhoneTarget.value);

    const newAddress = this.buildShippingAddressFromPopover(phoneValidation);

    let items = this.addressesValue;

    // remove pre existing edit address
    items = items.filter((item) => {
      return item.id != "editShipping";
    });

    items.push(newAddress);

    this.addressesValue = items;
    this.addressOptions = this.buildAddressOptions();

    const oldBillingValue = this.billingAddressOutlet.getSelected();

    this.updateAddressOptions();

    this.shippingAddressOutlet.setSelected("editShipping");
    this.billingAddressOutlet.setSelected(oldBillingValue);
  }

  shippingAddressCancel() {
    this.closeShippingAddressPopover();
  }

  async billingAddressApply() {
    this.closeBillingAddressPopover();

    const phoneValidation = await this.validatePhone(this.billingPhoneTarget.value);

    const newAddress = this.buildBillingAddressFromPopover(phoneValidation);

    let items = this.addressesValue;

    // remove pre existing edit address
    items = items.filter((item) => {
      return item.id != "editBilling";
    });

    items.push(newAddress);

    this.addressesValue = items;
    this.addressOptions = this.buildAddressOptions();

    const oldShippingValue = this.shippingAddressOutlet.getSelected();

    this.updateAddressOptions();

    this.billingAddressOutlet.setSelected("editBilling");
    this.shippingAddressOutlet.setSelected(oldShippingValue);
  }

  billingAddressCancel() {
    this.closeBillingAddressPopover();
  }

  // shipping fee popover

  changeShippingFee() {
    this.shippingFeePopoverTarget.classList.toggle("invisible");

    this.submitButtonTarget.disabled = true;
  }

  shippingFeeApply() {
    this.shippingFeeTarget.value = this.newShippingFeeTarget.value;
    this.shippingFeePopoverTarget.classList.toggle("invisible");

    this.submitButtonTarget.disabled = false;

    this.validateOrder();
  }

  shippingFeeCancel() {
    this.shippingFeePopoverTarget.classList.toggle("invisible");

    this.submitButtonTarget.disabled = false;
  }

  // Voucher popover

  addDiscount() {
    this.voucherPopoverTarget.classList.toggle("invisible");

    this.submitButtonTarget.disabled = true;
  }

  voucherApply() {
    this.voucherCodeTarget.value = this.newVoucherCodeTarget.value;
    this.voucherPopoverTarget.classList.toggle("invisible");

    this.submitButtonTarget.disabled = false;

    this.validateOrder();
  }

  voucherCancel() {
    this.newVoucherCodeTarget.value = "";
    this.voucherPopoverTarget.classList.toggle("invisible");

    this.submitButtonTarget.disabled = false;
  }
}
