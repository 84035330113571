import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element
    })
  }

  update(event) {
    const matrixId = event.target.dataset.matrixId;
    const matrixRuleId = event.target.dataset.matrixRuleId;
    const newRuleId = event.target.value;

    fetch(`/dispatch_matrices/${matrixId}/rules/${matrixRuleId}`, {
      method: "PATCH",
      body: JSON.stringify({
        dispatch_matrix_rule: {
          dispatch_rule_id: newRuleId
        }
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getCsrfToken()
      },
    })
      .then(response => {
        window.location.reload();
      });
  }

  getCsrfToken() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content");
  }
}
