import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["itemTemplate", "itemContainer", "isOpen", "addItemButton"]

  static values = {
    prefixName: String,
    prefixKey: String,
    prefixChildren: Boolean,
    avoidSelfPrefix: Boolean
  }

  connect() {
    if (this.prefixKeyValue === "") {
      this.prefixKeyValue = this.makeKey(8)
    }

    // set prefix
    if (!this.avoidSelfPrefixValue) {
      this.prefixInputElements(this.element)
    }
  }

  onAddItemClick(event) {
    // event.preventDefault()

    this.addItem()
  }

  onRemoveClick(event) {
    let parent_button = event.srcElement.closest("button")

    parent_button.parentElement.remove()
  }

  removeSpecialDate(event) {
    const dateContainer = event.target.closest('[data-controller="opening-hours"]');
    dateContainer.remove();
  }

  addItem() {
    const template = this.itemTemplateTarget

    const item = template.content.firstElementChild.cloneNode(true);

    if (this.prefixChildrenValue) {
      this.prefixInputElements(item)
    }

    let container = this.itemContainerTarget
    container.append(item);
  }

  prefixInputElements(element) {
    let elems = element.getElementsByTagName('input')

    // append prefix
    for (let i = 0; i < elems.length; i++) {
      let elem = elems[i]
      // TODO: check if already prefixed
      elem.setAttribute("name", this.buildInputPrefix() + elem.name)
    }
  }

  makeKey(length) {
    let result = ''
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  buildInputPrefix() {
    return `${this.prefixNameValue}[${this.prefixKeyValue}]`
  }

  toggleIsOpen() {
    if (this.isOpenTarget.checked) {
      // show button for adding new items
      this.addItemButtonTarget.style.display = "block";
    } else {
      // remove all opening hour items
      let container = this.itemContainerTarget
      while (container.hasChildNodes()) {
        container.firstChild.remove();
      }
      // hide button for adding new items
      this.addItemButtonTarget.style.display = "none";
    }
  }
}
