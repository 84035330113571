window.addEventListener('turbo:load', () => {
  $('[id$="-toggle-button"]').click(function(){
    var button_suffix = '-toggle-button';
    var section_suffix = '-toggle-section';
    var element_name = this.id.replace(button_suffix, '');

    var section_is_hidden = $('#' + element_name + section_suffix).hasClass('hidden');

    // hide all toggle-sections
    $('[id$="' + section_suffix + '"]').addClass('hidden');

    // show section in case it was hidden
    if (section_is_hidden) {
      $('#' + element_name + section_suffix).removeClass('hidden');
    }
  });
})