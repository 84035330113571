import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tgbt", "tgbtspan", "hideable", "permissionbtn", "savebtn"];

  static classes = ["btnon", "btnoff", "btnspanon", "btnspanoff"]

  onToggleClicked(event) {
    if (this.tgbtspanTarget.classList.contains(this.btnspanoffClass)) {
      this.tgbtspanTarget.classList.remove(this.btnspanoffClass);
      this.tgbtspanTarget.classList.add(this.btnspanonClass);

      this.tgbtTarget.classList.remove(this.btnoffClass);
      this.tgbtTarget.classList.add(this.btnonClass);
    } else {
      this.tgbtspanTarget.classList.remove(this.btnspanonClass);
      this.tgbtspanTarget.classList.add(this.btnspanoffClass);

      this.tgbtTarget.classList.remove(this.btnonClass);
      this.tgbtTarget.classList.add(this.btnoffClass);
    }

    this.hideableTargets.forEach(el => {
      if (el.attributes['permissions'].value === 'none') {
        el.hidden = !el.hidden
      }
    });
  }

  onPermissionButtonClicked(event) {
    event.srcElement.classList.toggle("line-through");
  }

  onSaveButtonClicked(event) {
    let role_id = event.srcElement.attributes['role_id'].value
    let group_name = event.srcElement.attributes['group_name'].value

    let permissions = []

    $(event.srcElement.parentElement).find("button").each(
      function () {
        let node = $(this)

        let name = node.attr('permission_name');
        let value = !node.hasClass('line-through');

        if (typeof (name) === 'undefined') { return; }

        let entry = `"${name}": ${value}`

        permissions.push(entry);
      }
    );

    let json = `{"role_id": ${role_id}, "group_name": "${group_name}", "permissions": {${permissions.join()}}}`

    fetch(`/api/v2/roles/${role_id}/permissions`, {
      method: 'post',
      body: json
    })
  }
}