import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = ["selectAll", "select"]

  connect() {
    this.select_element = new SlimSelect({
      select: this.element.getElementsByTagName("SELECT").item(0)
    })     
  }

  onClickAll() {
    if (this.selectAllTarget.checked) {
      this.select_element.setSelected([])
    }
  }

  onChange() {
    let selected_values = this.select_element.getSelected()

    if (selected_values.length > 0) {
      this.selectAllTarget.checked = false
    }
  }

  removeSelections() {
    console.log('remove slections...')
    this.selectTarget.childNodes.forEach(item => {
      console.log('nodeName = ' + item.nodeName)
      if (item.nodeName == "OPTION") {
        item.removeAttribute('selected')
      }
    })
  }
}