import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tabSelect"]

  onSelectionChange() {
    for (let i = 0; i < this.tabSelectTarget.children.length; i++) {
      let item = this.tabSelectTarget.children.item(i)
      if (item.selected) {
        window.location = item.getAttribute('option-url')
      }
    }
  }
}