import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timeline-item"
export default class extends Controller {
  static targets =["expandButton", "itemProps"]

  connect() {
  }

  expand() {
    this.expandButtonTarget.classList.toggle("rotate-90")

    this.itemPropsTarget.hidden = !this.itemPropsTarget.hidden
  }
}
