import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "map", "lng", "lat", "deliveryRadius", "polygonVertices", "polygonVerticesX", "areaType", "circleRadius",
    "polygonLat", "polygonLon", "polygonRadius", "polygonMinRadius"
  ]

  static values = {
    nearbyAreas: Array,
    readOnly: Boolean
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) {
      return;
    }

    if (typeof (google) != "undefined") {
      this.initializeMap();
    }
  }

  initializeMap() {
    this.map()
    this.marker()

    this.circle()

    this.drawNearbyAreas()

    if (!this.hasLatTarget || !this.hasAreaTypeTarget) {
      return
    }

    this.polygon()

    this.parsePolygonVertices()
  }

  map() {
    if (this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(this.data.get("pointLat") || 51.465623, this.data.get("pointLng") || -0.155084),
        zoom: 13,
        streetViewControl: false,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT,
        }
      })
    }

    return this._map
  }

  marker() {
    if (!this.hasLatTarget) {
      return
    }

    this.marker = new google.maps.Marker({
      map: this._map,
      label: "R",
      anchorPoint: new google.maps.Point(51.465623, -0.155084)
    })

    this.marker.setPosition(new google.maps.LatLng(Number(this.latTarget.value), Number(this.lngTarget.value)))
    this.marker.setVisible(true)
  }

  circle() {
    if (!this.hasLatTarget) {
      return
    }

    this.circle = new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: this._map,
      center: new google.maps.LatLng(this.latTarget.value || 51.465623, this.lngTarget.value || -0.155084),
      radius: this.circleRadius()
    })

    this.circle.setVisible(this.typeIsCircle())
  }

  createCircle(center, radius) {
    return new google.maps.Circle({
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.0,
      map: this._map,
      center: center,
      radius: radius
    })
  }

  circle2() {
    this.maxCircle = this.createCircle(
      new google.maps.LatLng(this.polygonLatTarget.value, this.polygonLonTarget.value),
      this.circleRadius2()
    )

    this.minCircle = this.createCircle(
      new google.maps.LatLng(this.polygonLatTarget.value, this.polygonLonTarget.value),
      Number(this.polygonMinRadiusTarget.value)
    )
  }

  drawNearbyAreas() {
    for (let i in this.nearbyAreasValue) {
      this.drawNearbyArea(this.nearbyAreasValue[i])
    }
  }

  retrieveAreaInfoWindow(area) {
    if (!area.infoWindow) {
      const content =
        '<div id="content">' +
        '<div class="title full-width" style="font-weight:500; font-size:14px;">' + area.site + '</div>' +
        '<p><b>Postcode:</b> ' + area.postcode + '</p>'
      "</div>";

      area.infoWindow = new google.maps.InfoWindow({
        content: content,
        ariaLabel: area.site,
      });
    }

    return area.infoWindow
  }

  drawMarkerAndInfo(area) {
    let marker = new google.maps.Marker({
      map: this._map,
      title: area.site
    })

    marker.setPosition(new google.maps.LatLng(Number(area.lat), Number(area.lon)))
    marker.setVisible(true)

    marker.addListener("click", () => {
      let infoWindow = this.retrieveAreaInfoWindow(area)

      infoWindow.open({
        anchor: marker,
        map: this._map
      });
    });
  }

  drawNearbyArea(area) {
    this.drawMarkerAndInfo(area)

    if (area.area_type === 'polygon') {
      this.drawPolygon(area.polygon_vertices)
      return
    }

    this.drawCircle(area.lat, area.lon, area.circle_radius)
  }

  drawPolygon(polygon_vertices) {
    let polygon = new google.maps.Polygon({
      strokeColor: "#0000ff",
      map: this._map,
      strokeWeight: 2,
      fillColor: "#0000ff",
      fillOpacity: 0.3,
      zIndex: 99999,
      editable: false,
      draggable: false,
      visible: true
    });

    this.drawPolygonVertices(polygon, polygon_vertices)
  }

  drawCircle(lat, lon, radius) {
    let circle = new google.maps.Circle({
      strokeColor: "#0000FF",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#0000FF",
      fillOpacity: 0.35,
      map: this._map,
      center: new google.maps.LatLng(Number(lat), Number(lon)),
      radius: Number(radius)
    })

    circle.setVisible(true)
  }

  typeIsCircle() {
    return this.areaTypeTarget.value === "circle"
  }

  typeIsPolygon() {
    return this.areaTypeTarget.value === "polygon"
  }

  circleRadius() {
    return Number(this.circleRadiusTarget.value)
  }

  circleRadius2() {
    return Number(this.polygonRadiusTarget.value)
  }

  refreshVisibility() {
    this.circle.setVisible(this.typeIsCircle())
    this.polygon.setVisible(this.typeIsPolygon())
  }

  polygon() {
    this.polygon = new google.maps.Polygon({
      strokeColor: "#ff0000",
      map: this._map,
      strokeWeight: 2,
      fillColor: "#ff0000",
      fillOpacity: 0.3,
      zIndex: 99999,
      editable: !this.readOnlyValue,
      draggable: false,
    });

    this.polygon.setVisible(this.typeIsPolygon())

    google.maps.event.addListener(this.polygon, "contextmenu", (e) => {
      // Check if click was on a vertex control point
      if (e.vertex == undefined) {
        return;
      }
      this.polygon.getPath().removeAt(e.vertex)
    });
  }

  drawPolygonCenter() {
    this.polygonCenter = new google.maps.Marker({
      map: this._map,
      position: new google.maps.LatLng(Number(this.polygonLatTarget.value), Number(this.polygonLonTarget.value)),
      label: "C"
    })
    this.polygonCenter.setVisible(true)
  }

  applyRadius() {
    this.circle.setRadius(Number(this.deliveryRadiusTarget.value));
  }

  getCoordinates() {
    this.polygonVertices();
  }

  polygonVertices() {
    const vertices = this.polygon.getPath();

    let items = []
    for (let i = 0; i < vertices.getLength(); i++) {
      const vertice = vertices.getAt(i);

      items.push({ lat: vertice.lat(), lng: vertice.lng() })
    }

    this.polygonVerticesTarget.value = JSON.stringify(items)
  }

  refreshPolygonVertices() {
    const vertices = this.polygon.getPath();

    let items = []
    for (let i = 0; i < vertices.getLength(); i++) {
      const vertice = vertices.getAt(i);
      items.push(vertice.lat())
      items.push(vertice.lng())
    }

    this.polygonVerticesTarget.value = JSON.stringify(items)
  }

  async drawPolygonVertices(polygon, vertices) {
    let aux = []
    for (let i = 0; i < vertices.length; i += 2) {
      aux.push(new google.maps.LatLng(Number(vertices[i]), Number(vertices[i + 1])))
    }

    polygon.setPaths(aux)
  }

  async parsePolygonVertices() {
    let res = this.polygonVerticesTarget.value;

    let jres = await JSON.parse(res);

    let aux = []
    for (let i = 0; i < jres.length; i += 2) {
      aux.push(new google.maps.LatLng(Number(jres[i]), Number(jres[i + 1])))
    }

    this.polygon.setPaths(aux)

    google.maps.event.addListener(this.polygon.getPath(), 'set_at', (e) => {
      this.refreshPolygonVertices()
    });

    google.maps.event.addListener(this.polygon.getPath(), 'insert_at', (e) => {
      this.refreshPolygonVertices()
    });

    google.maps.event.addListener(this.polygon.getPath(), 'remove_at', (e) => {
      this.refreshPolygonVertices()
    });
  }

  async parseJson() {
    let res = this.polygonVerticesTarget.value;

    let jres = await JSON.parse(res);

    let aux = []
    for (let i = 0; i < jres.length; i++) {
      const item = jres[i]
      aux.push(new google.maps.LatLng(item.lat, item.lng))
    }

    this.polygon.setPaths(aux)
  }

  areaTypeChanged() {
    this.refreshVisibility()
  }

  circleRadiusChanged() {
    this.circle.setRadius(this.circleRadius())
  }

  applyMarkers() {
    this.parseJsonX()
  }

  async parseJsonX() {
    let res = this.polygonVerticesXTarget.value;

    let jres = await JSON.parse(res);

    for (let i = 0; i < jres.length; i += 2) {
      const itemLat = jres[i]
      const itemLng = jres[i + 1]

      this.drawMarker(itemLat, itemLng)
    }
  }

  drawMarker(lat, lng) {
    this.marker = new google.maps.Marker({
      map: this._map,
      anchorPoint: new google.maps.Point(lat, lng)
    })

    this.marker.setPosition(new google.maps.LatLng(Number(lat), Number(lng)))
    this.marker.setVisible(true)
  }
}
