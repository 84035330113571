import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="selection-dependency"
export default class extends Controller {
  static values = {
    parentId: String,
    visibility: Array
  };

  connect() {
    this.addEventListener();
    this.updateVisibility();
  }

  addEventListener() {
    const parent = document.getElementById(this.parentIdValue)

    parent.addEventListener("change", this.parentValueChanged.bind(this))
  }

  parentValueChanged(event) {
    this.updateVisibility()
  }

  updateVisibility() {
    const parent = document.getElementById(this.parentIdValue)

    const isVisible = this.visibilityValue.includes(parent.value)

    if (isVisible) {
      this.element.classList.remove("hidden")
    } else {
      this.element.classList.add("hidden")
    }
  }
}