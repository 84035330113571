import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="kitchen-orders"
export default class extends Controller {
  connect() {
    this.refreshTimer = setInterval(() => {
      this.refreshOrders()
    }, 15000)
  }

  disconnect() {
    clearInterval(this.refreshTimer)
  }

  refreshOrders() {
    this.element.reload();
  }
}
